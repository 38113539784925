.tooltip-container {
  position: relative;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 8px;
  z-index: 1000;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}
