.calendar-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: auto;
}

/* Takvim genel stilleri */
.rbc-calendar {
  width: 100%;
  min-width: 900px; /* Yatay kaydırma için minimum genişlik */
}

.rbc-time-view {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

/* Event stilleri */
.rbc-event {
  padding: 4px 8px !important;
  margin: 1px 0 !important;
  border-radius: 6px !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.rbc-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.rbc-event.rbc-selected {
  background-color: inherit !important;
  opacity: 0.9;
}

/* Zaman gösterimi stilleri */
.rbc-time-content {
  border-top: 1px solid #e5e7eb !important;
}

.rbc-timeslot-group {
  min-height: 80px !important; /* Saat aralıklarını genişlet */
  border-bottom: 1px solid #f3f4f6 !important;
}

.rbc-time-slot {
  font-size: 0.875rem !important;
  color: #6b7280 !important;
}

.rbc-time-gutter {
  font-weight: 500 !important;
}

/* Başlık ve toolbar stilleri */
.rbc-toolbar {
  margin-bottom: 1.5rem !important;
  padding: 0.75rem !important;
  background-color: #f9fafb;
  border-radius: 8px;
}

.rbc-toolbar button {
  padding: 0.5rem 1rem !important;
  background: #ffffff !important;
  color: #374151 !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.rbc-toolbar button:hover {
  background: #f3f4f6 !important;
  border-color: #d1d5db !important;
}

.rbc-toolbar button.rbc-active {
  background: #3b82f6 !important;
  color: white !important;
  border-color: #3b82f6 !important;
}

/* Kaydırma çubuğu stilleri */
.calendar-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.calendar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.calendar-container::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 4px;
}

.calendar-container::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

/* Çakışan eventler için stil */
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px rgba(0,0,0,0.1) !important;
}

/* Event renk sınıfları */
.event-blue {
  background-color: #3B82F6 !important;
  color: white !important;
}

.event-green {
  background-color: #10B981 !important;
  color: white !important;
}

.event-red {
  background-color: #EF4444 !important;
  color: white !important;
}

.event-purple {
  background-color: #8B5CF6 !important;
  color: white !important;
}

.event-orange {
  background-color: #F97316 !important;
  color: white !important;
}

/* Hover efektleri */
.event-blue:hover,
.event-green:hover,
.event-red:hover,
.event-purple:hover,
.event-orange:hover {
  opacity: 0.8 !important;
  cursor: pointer;
}

/* Modal stilleri */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 90%;
  width: 500px;
  z-index: 9999 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998 !important;
}

/* react-big-calendar özel stil düzeltmeleri */
.rbc-current-time-indicator {
  z-index: 1 !important;
}

.rbc-event {
  z-index: 2 !important;
}
