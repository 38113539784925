.modalContent {
    max-width: 90%;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    position: relative;
    top: 50px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #888;
    cursor: pointer;
}

.tableContainer {
    margin-top: 20px;
}

.tableHeader, .tableRow {
    padding: 12px 16px;
    text-align: center; /* Center-aligns content in both headers and rows */
}

.tableRow:hover {
    background-color: #f0f0f0;
}

.backButton {
    margin-bottom: 20px;
    background-color: #e0e0e0;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}
